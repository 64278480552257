import consumer from "./consumer"

consumer.subscriptions.create({ channel: "TaskChannel", task: rjsVar }, {
  
  connected: function() {
    console.log("Channel Connected for task " + rjsVar);
  },
  disconnected: function() {
    console.log("Channel Disc");
  },
  received: function(data) {
	  return $('#messages').append(this.renderMessage(data));
  },
	  	
  renderMessage: function(data) {
    
        var message_type =  data.messageType
        var text_message = data.message
        
        if (message_type == 'debug') { 
          return "<p class='text-secondary'> <span class='p2 bg-secondary text-white'> &nbsp" + message_type  + "&nbsp</span> &nbsp" +  text_message +  "</p>";
         } 
        if (message_type == 'error') {
          return "<p class='text-danger'> <span class='p2 bg-danger text-white'> &nbsp" + message_type  + "&nbsp</span> &nbsp" +  text_message +  "</p>";
        } 
        if (message_type == 'warn') {
          return "<p class='text-warning'>  <span class='p2 bg-warning text-dark'> &nbsp" + message_type  + "&nbsp</span> &nbsp" +  text_message +  "</p>";
        } 
        if (message_type == 'info') {
          return "<p class='text-body'>  <span class='p2 bg-primary text-white'> &nbsp" + message_type  + "&nbsp</span> &nbsp" +  text_message +  "</p>";
        } 
   
 
        if (message_type == 'finished') {
          return "<p class='text-success'>  <span class='p2 bg-success text-white'> &nbsp TASK TERMINAT CU SUCCES! &nbsp</span> </p>";

        } 
		
        if (message_type == 'finished_with_errors') {
          return "<p class='text-danger'>  <span class='p2 bg-danger text-white'> &nbsp TASK TERMINAT CU ERRORI! &nbsp</span> </p>";
        } 

  }
	
	
});
