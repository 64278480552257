// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("jquery")
require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")




// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import 'owl.carousel2/dist/owl.carousel.min.js';
import './bootstrap_custom.js' 

import './lightgallery.js' 
import 'lightslider/src/js/lightslider.js'

 

$(document).on('turbolinks:load', function() {
  
  $(".sort-me").sortable({
      update: function(e, ui){
        ui.placeholder.height(ui.item.height());
        $.ajax({
          url: $(this).data("url"),
          type: "PATCH",
          data: $(this).sortable('serialize')
        });
      }
    }).disableSelection();


  $('#imageGallery').lightSlider({
      gallery:true,
      item:1,
      loop:true,
      thumbItem:9,
      slideMargin:0,
      enableDrag: false,
      currentPagerPosition:'left',
      onSliderLoad: function(el) {
          el.lightGallery({
              selector: '#imageGallery .lslide'
          })
      }
      });    
          
          
    
  $('.owl-carousel').owlCarousel({
    autoplay: true,
    autoplayHoverPause: true,
    lazyLoad: true,
    margin: 5,
    stagePadding: 5,
    nav: false,
    //navText: ["<i class='fa fa-2x fa-chevron-left'></i>", "<i class='fa fa-2x fa-chevron-right'></i>"],
    dots: true,

    
    loop: true,
  
    responsive:{
        0:{
            items:1,
            dots: false
        },
        600:{
            items:1,
            dots: true
        },
        1000:{
            items:2,
            dots: true
        },
        1200:{
            items:3,
            dots: true
        }
    }
 });
 
 function setOwlStageHeight(event) {
     var maxHeight = 0;
     $('.owl-item.active').each(function () { // LOOP THROUGH ACTIVE ITEMS
         var thisHeight = parseInt( $(this).height() );
         maxHeight=(maxHeight>=thisHeight?maxHeight:thisHeight);
     });
     $('.owl-carousel').css('height', maxHeight );
     $('.owl-stage-outer').css('height', maxHeight ); // CORRECT DRAG-AREA SO BUTTONS ARE CLICKABLE
 }
 /*
 $('.owl-carousel').on('mousewheel', '.owl-stage', function (e) {
     if (e.deltaY>0) {
         $('.owl-carousel').trigger('next.owl');
     } else {
         $('.owl-carousel').trigger('prev.owl');
     }
     e.preventDefault();
 });
 */
}); 



require("trix")
require("@rails/actiontext")